import { useState, useEffect, useContext } from 'react'
import { Table } from 'rsuite'
import { MdExpandMore, MdExpandLess, MdOutlineCreate, MdMailOutline, MdOutlineDrafts, MdOutlineMouse } from 'react-icons/md'
import axios from '../../api/axios'
import { TotalsCampaigns, Campaign } from '../../interfaces'
import AuthContext from '../../context/AuthProvider'

const { Column, HeaderCell, Cell } = Table

const Campaigns: React.FC = () => {
	const { auth } = useContext(AuthContext)
	const [campaigns, setCampaigns] = useState<Campaign[]>([])
	const [totalsCampaigns, setTotalsCampaigns] = useState<TotalsCampaigns>({
		campaigns: 0,
		mailsend: 0,
		mailopened: 0,
		clicks: 0,
	})
	const [sortColumn, setSortColumn] = useState<any>()
  	const [sortType, setSortType] = useState<any>()
	const [loading, setLoading] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	
	
	useEffect(() => {
		const getActiveCampaignInfos = () => {
			axios.get('/activecampaign/overall', { headers: {'Authorization': `Bearer ${auth.accessToken}`} })
				.then(res => {
					const { data } = res.data
					setCampaigns(data)
				})
				.catch(error => {
					throw new Error(error)
				})
		}
		getActiveCampaignInfos()
	}, [])
	
	useEffect(() => {
		setTotalsCampaigns({
			campaigns: campaigns.length,
			mailsend: campaignsReducer('send_amt'),
			mailopened: campaignsReducer('uniqueopens'),
			clicks: campaignsReducer('uniquelinkclicks'),
		})
	}, [campaigns])
	
	useEffect(() => {
		if(sortColumn && sortType) {
			campaigns.sort((a: any, b: any) => {
				let x = a[sortColumn]
				let y = b[sortColumn]
				
				if(typeof x === 'string') {
					x = +x.slice(1,3)
				}
				if(typeof y === 'string') {
					y = +y.slice(1,3)
				}
				
				if(sortType === 'asc') {
					return x - y
				} else {
					return y - x
				}
			})
		}
	})
	
	const campaignsReducer = (property: string) => {
		const reducedArr = new Array()
		for(let i = 0; i < campaigns.length; i++) {
			//@ts-ignore
			reducedArr.push(campaigns[i][property])
		}
		return reducedArr.reduce((prev, curr) => prev + curr, 0)
	}

	const handleClick = () => {
		setIsOpen(prev => !prev)
	}

	const handleSortColumn = (sortColumn: any, sortType: any) => {
		setLoading(true)
    	setTimeout(() => {
			setLoading(false)
			setSortColumn(sortColumn)
			setSortType(sortType)
    	}, 500)
	}

	return (
		<section className='Campaign'>
			<div className={isOpen ? `Campaign-activable open` : `Campaign-activable`} onClick={handleClick}>
				<div className="Campaign-activable-item">
					<span>Campagnes <MdOutlineCreate /></span>
					<span>{totalsCampaigns.campaigns}</span>
				</div>

				<div className="Campaign-activable-item">
					<span>E-mails envoyés <MdMailOutline /></span>
					<span>{totalsCampaigns.mailsend}</span>
				</div>

				<div className="Campaign-activable-item">
					<span>E-mails ouverts <MdOutlineDrafts /></span>
					<span>{totalsCampaigns.mailopened}</span>
				</div>

				<div className="Campaign-activable-item">
					<span>Clics <MdOutlineMouse /></span>
					<span>{totalsCampaigns.clicks}</span>
				</div>

				<div className="Campaign-activable-item">
					{isOpen ? <MdExpandLess /> : <MdExpandMore />}
				</div>
			</div>

			{isOpen ? (
			<Table
				autoHeight
				data={campaigns}
				sortColumn={sortColumn}
				sortType={sortType}
				onSortColumn={handleSortColumn}
				loading={loading}
			>
				<Column flexGrow={2} sortable>
					<HeaderCell>Campagne</HeaderCell>
					<Cell dataKey='name' />
				</Column>
				
				<Column flexGrow={1} sortable>
					<HeaderCell>E-mails envoyés</HeaderCell>
					<Cell dataKey='send_amt' />
				</Column>

				<Column flexGrow={1} sortable>
					<HeaderCell>E-mails ouverts</HeaderCell>
					<Cell dataKey='uniqueopens' />
				</Column>
				
				<Column flexGrow={1} sortable>
					<HeaderCell>Clics</HeaderCell>
					<Cell dataKey='uniquelinkclicks' />
				</Column>
			</Table>
			) : null}
		</section>
	)
}
export default Campaigns