import { useContext, useEffect, useState } from 'react'
import axios from '../../api/axios'
import AuthContext from '../../context/AuthProvider'
import { User } from '../../interfaces'
import Navbar from '../../components/Navbar'
import Menu from '../../components/Menu'
import Main from '../../components/Main'
import './Home.scss'

const Home: React.FC = () => {
	const { auth } = useContext(AuthContext)
	const [user, setUser] = useState<User>({
		first_name: '',
		last_name: '',
		company: '',
		email: '',
		automation_id: '',
		spreadsheet_id: '',
		act_id: '',
		role: '',
	})
	const [activeTab, setActiveTab] = useState<Number>(0)

	useEffect(() => {
		const getUser = () => {
			axios.get('users/infos', { headers: {'Authorization': `Bearer ${auth.accessToken}`} })
				.then(res => setUser(res.data))
				.catch(error => {
					throw new Error(error)
				})
		}
		getUser()
	}, [])

	return (
		<main className='Home'>
			<Navbar setActiveTab={setActiveTab}  />

			<div className="Home-right-section">
				<Menu user={user} />
				<Main activeTab={activeTab} user={user} />
			</div>
		</main>
	)
}
export default Home