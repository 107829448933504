import { User } from '../../interfaces'
import Dashboard from '../Dashboard'
import Statistics from '../Statistics'
import Leads from '../Leads'
import Settings from '../Settings'
import './Main.scss'

interface Props {
	activeTab: Number
	user: User
}

const Main: React.FC<Props> = ({ activeTab, user }) => {
	switch(activeTab) {
		case 0:
			return <Dashboard />
		case 1:
			return <Statistics />
		case 2:
			return <Leads />
		case 3:
			return <Settings user={user} />

		default:
			return (
				<section className='Main'>
					<h1>Bienvenue sur Le Labo</h1>
				</section>
			)
	}
}
export default Main