import { User } from '../../interfaces'
import './UserInfos.scss'

interface Props {
	user: User | undefined
}

const UserInfos: React.FC<Props> = ({ user }) => {
	return (
		<div className='UserInfos'>
			<div className="user-photo">
			
			</div>
			<div className="user-wrapper">
				<p className='user-name'>{`${user?.first_name} ${user?.last_name}`}</p>
				<p className='user-company'>{user?.company}</p>
			</div>
		</div>
	)
}
export default UserInfos