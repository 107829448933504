import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { InfosPerMonth } from '../../interfaces'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend)

interface Props {
	infosPerMonth: InfosPerMonth
}

const LineChart: React.FC<Props> = ({ infosPerMonth }) => {
	const options = {
		responsive: true,
		plugins: {
			legend: { position: 'bottom' as const },
		},
		scales: {
			x: { grid: { display: false } },
		},
	}

	let { guides, appointments } = infosPerMonth
	let guidesSum: any, appointmentsSum: any
	guides = guides.map(elem => guidesSum = (guidesSum || 0) + elem)
	appointments = appointments.map(elem => appointmentsSum = (appointmentsSum || 0) + elem)

	const data = {
		labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre'],
		datasets: [
			{
				id: 1,
				label: 'Nouveaux prospects',
				data: guides,
				borderWidth: 7,
				borderColor: 'rgb(0, 140, 255)',
				backgroundColor: 'rgb(0, 140, 255)',
			},
			{
				id: 2,
				label: 'Demandes de RDV',
				data: appointments,
				borderWidth: 7,
				borderColor: 'rgb(27, 207, 180)',
				backgroundColor: 'rgb(27, 207, 180)',
			},
		],
	}

	return <Line options={options} data={data} />
}
export default LineChart