import * as yup from 'yup'

const emptyMsg: string = 'Champ requis'
const longMsg: string = 'Champ trop long'
const emailFormatMsg: string = 'Format e-mail invalide'

export const signInSchema = yup.object().shape({
	email: yup.string().email(emailFormatMsg).required(emptyMsg),
	password: yup.string().required(emptyMsg),
})

export const infosSchema = yup.object().shape({
	first_name: yup.string().max(50, longMsg),
	last_name: yup.string().max(50, longMsg),
	facebook_url: yup.string().max(255, longMsg),
	instagram_url: yup.string().max(255, longMsg),
	website_url: yup.string().max(255, longMsg),
})

export const addressSchema = yup.object().shape({
	street: yup.string().max(50, longMsg).required(emptyMsg),
	city: yup.string().max(50, longMsg).required(emptyMsg),
	zipcode: yup.string().length(5, 'Format invalide').required(emptyMsg),
	country: yup.string().max(50, longMsg).required(emptyMsg),
})

export const passwordSchema = yup.object().shape({
	password: yup.string().max(255, longMsg).required(emptyMsg),
	newPassword: yup
	.string()
	.min(5, 'Mot de passe trop court')
	.max(255, longMsg)
	.required(emptyMsg),
})