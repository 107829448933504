import { Link } from 'react-router-dom'
import './NotFound.scss'

const NotFound: React.FC = () => {
  return (
		<div className='NotFound'>
			<h1>404</h1>
			<p>Page introuvable. <Link to='/'>Retourner à l'accueil</Link></p>
		</div>
  )
}
export default NotFound