import { MdDashboard, MdSettings, MdPeopleAlt, MdOutlineQueryStats} from 'react-icons/md'
import './Navbar.scss'

interface Props {
	setActiveTab: React.Dispatch<React.SetStateAction<Number>>
}

const Navbar: React.FC<Props> = ({ setActiveTab }) => {
	return (
		<nav className='Navbar'>
			<div className="Navbar-logo">
				<img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt='Logo Le Labo' />
			</div>

			<div className="Navbar-links">
				<div
					className="link"
					onClick={() => {setActiveTab(0)}}
				>
					<p>Tableau de bord</p>
					<MdDashboard className='icon' />
				</div>

				<div
					className="link"
					onClick={() => setActiveTab(1)}
				>
					<p>Statistiques</p>
					<MdOutlineQueryStats className='icon' />
				</div>

				<div
					className="link"
					onClick={() => setActiveTab(2)}
				>
					<p>Mes prospects</p>
					<MdPeopleAlt className='icon' />
				</div>

				<div
					className="link"
					onClick={() => setActiveTab(3)}
				>
					<p>Paramètres</p>
					<MdSettings className='icon' />
				</div>
			</div>
		</nav>
	)
}
export default Navbar