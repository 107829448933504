import { startOfDay, endOfDay, subDays } from 'rsuite/esm/utils/dateUtils'
import { RangeType } from 'rsuite/esm/DateRangePicker'

const year = new Date().getUTCFullYear()
const month = new Date().getUTCMonth()
const day = new Date().getUTCDate()

export const today = new Date(year, month, day, 12)
export const firstDayOfTheMonth = new Date(year, month, 1, 12)
export const locale = {
	monday: 'Lu',
	tuesday: 'Ma',
	wednesday: 'Me',
	thursday: 'Je',
	friday: 'Ve',
	saturday: 'Sa',
	sunday: 'Di',
	ok: 'Mettre à jour',
	formattedMonthPattern: 'MMM yyyy',
	formattedDayPattern: 'dd MMM yyyy',
}
export const ranges: RangeType[] | any = [
	{
		label: 'Maximum',
		value: [startOfDay(new Date('2021-11-09')), endOfDay(today)],
	},
	{
		label: "Aujourd'hui",
		value: [startOfDay(today), endOfDay(today)],
	},
	{
		label: '7 derniers jours',
		value: [startOfDay(subDays(today, 7)), endOfDay(today)],
	},
	{
		label: '14 derniers jours',
		value: [startOfDay(subDays(today, 14)), endOfDay(today)],
	},
	{
		label: '30 derniers jours',
		value: [startOfDay(subDays(today, 30)), endOfDay(today)],
	},
	{
		label: 'Ce mois-ci',
		value: [firstDayOfTheMonth, today],
	},
]