import { useContext } from 'react'
import { MdNotificationsNone, MdPowerSettingsNew } from 'react-icons/md'
import UserInfos from '../UserInfos'
import AuthContext from '../../context/AuthProvider'
import { User } from '../../interfaces'
import './Menu.scss'

interface Props {
	user: User | undefined
}

const Menu: React.FC<Props> = ({ user }) => {
	const { setAuth } = useContext(AuthContext)

	return (
		<section className="Menu">
			<div></div>
			<div className="controls">
				<UserInfos user={user} />

				<button>
					<MdNotificationsNone className='icon' />
				</button>

				<button onClick={() => setAuth(null)}>
					<MdPowerSettingsNew className='icon'/>
				</button>
			</div>
		</section>
	)
}
export default Menu