import './Statistics.scss'

const Stats: React.FC = () => {
	return (
		<section className='Main'>
			<p>Statistiques</p>

			<div className="graph-container">
				
			</div>

			<div className="stats-container">
				<div className="stats-item"></div>
				<div className="stats-item"></div>
				<div className="stats-item"></div>
			</div>
		</section>
	)
}
export default Stats