import { useEffect, useContext, useState } from 'react'
import { Table, Pagination } from 'rsuite'
import axios from '../../api/axios'
import AuthContext from '../../context/AuthProvider'
import { Lead } from '../../interfaces'

const { Column, HeaderCell, Cell } = Table

const Leads: React.FC = () => {
	const { auth } = useContext(AuthContext)
	const [leads, setLeads] = useState<Lead[]>([])
	const [limit, setLimit] = useState<number>(10)
	const [page, setPage] = useState<number>(1)

	useEffect(() => {
		const getLeads = async () => {
			axios.get('google/leads', { headers: {'Authorization': `Bearer ${auth.accessToken}`} })
				.then(res => {
					const { data } = res.data
					const formatedData = data.map((curr: any, i: number, arr: any) => {
						return {
							name: arr[i][0],
							mail: arr[i][1],
							date: arr[i][2],
						}
					})
					setLeads(formatedData)
				})
				.catch(error => {
					throw new Error(error)
				})
		}
		getLeads()
	}, [])

	const handleChangeLimit = (dataKey: number) => {
		setPage(1)
		setLimit(dataKey)
	}

	const data = leads?.filter((curr, i) => {
		const start = limit * (page - 1)
		const end = start + limit
		return i >= start && i < end
	})

	return (
		<section className="Main">
			<p>Mes prospects</p>

			<Table
				autoHeight
				data={data}
				headerHeight={40}
				rowHeight={46}
			>
				<Column flexGrow={1}>
					<HeaderCell>Nom</HeaderCell>
					<Cell dataKey='name' />
				</Column>

				<Column flexGrow={1}>
					<HeaderCell>E-mail</HeaderCell>
					<Cell dataKey='mail' />
				</Column>

				<Column flexGrow={1}>
					<HeaderCell>Date d'entrée</HeaderCell>
					<Cell dataKey='date' />
				</Column>
			</Table>

			<div>
				<Pagination
					prev
					next
					first
					last
					ellipsis
					maxButtons={5}
					size='xs'
					layout={['total', '-', 'limit', '|', 'pager', 'skip']}
					total={leads.length}
					limitOptions={[10, 25, 50]}
					limit={limit}
					activePage={page}
					onChangePage={setPage}
					onChangeLimit={handleChangeLimit}
				/>
			</div>

		</section>
	)
}
export default Leads