import { useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import axios from '../../../api/axios'
import { User } from '../../../interfaces'
import { infosSchema } from '../../../schemas'
import AuthContext from '../../../context/AuthProvider'

interface Props {
	activeTab: number
	user: User
}

const InfosForm: React.FC<Props> = ({ activeTab, user }) => {
	const { auth } = useContext(AuthContext)

	const options = {
		headers: {
			'Authorization': `Bearer ${auth.accessToken}`
		}
	}

	const updateInfos = async (values: User) => {
		axios.patch('/users/infos', values, options)
			.then(res => {
				if(res.status != 201) {
					return toast.error('Erreur lors de la modification de votre adresse.', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					})
				}

				toast.success('Votre adresse a été modifiée avec succès.', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				})
			})
			.catch(error => {
				throw new Error(error)
			})
	}

	return (
		<Formik
			initialValues={user}
			validationSchema={infosSchema}
			enableReinitialize={true}
			onSubmit={(values: User, { setSubmitting }) => {
				updateInfos(values)
				setSubmitting(false)
			}}
		>
			<Form className={activeTab === 0 ? 'infos-form active' : 'infos-form'}>
				<div className='infos-form-section'>
					<p>Informations personnelles</p>
					<div>
						<label htmlFor='first_name'>Prénom :</label>
						<Field
							type='text'
							name='first_name'
							placeholder='Prénom'
						/>
						<ErrorMessage name='first_name' component='p' />
					</div>
					<div>
						<label htmlFor='last_name'>Nom :</label>
						<Field
							type='text'
							name='last_name'
							placeholder='Nom'
						/>
						<ErrorMessage name='last_name' component='p' />	
					</div>
				</div>

				<div className='infos-form-section'>
					<p>Informations supplémentaires</p>
					<div>
						<label htmlFor='facebook_url'>URL Facebook :</label>
						<Field
							type='url'
							name='facebook_url'
							placeholder='URL de votre page Facebook'
						/>
						<ErrorMessage name='facebook_url' component='p' />
					</div>
					
					<div>
						<label htmlFor='instagram_url'>URL Instagram :</label>
						<Field
							type='url'
							name='instagram_url'
							placeholder='URL de votre page Instagram'
						/>
						<ErrorMessage name='instagram_url' component='p' />
					</div>

					<div>
						<label htmlFor='website_url'>Site web :</label>
						<Field
							type='url'
							name='website_url'
							placeholder='URL de votre site web'
						/>
						<ErrorMessage name='website_url' component='p' />
					</div>
				</div>

				<div className="infos-form-section">
					<button type='submit'>Modifier mes informations</button>
				</div>
			</Form>
		</Formik>
	)
}
export default InfosForm