import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import SignIn from './pages/SignIn'
import RequireAuth from './components/RequireAuth'
import Home from './pages/Home'
import NotFound from './pages/NotFound'

export const App: React.FC = () => {
	return (
		<Routes>
			<Route path='/' element={<Layout />}>
				<Route path='/signin' element={<SignIn />} />
				<Route element={<RequireAuth />}>
					<Route path='/' element={<Home />} />
				</Route>
				<Route path='*' element={<NotFound />} />
			</Route>
		</Routes>
	)
}
export default App