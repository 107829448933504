import { useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from '../../api/axios'
import useAuth from '../../hooks/useAuth'
import { signInSchema } from '../../schemas'
import './SignIn.scss'

interface FormValues {
	email: string
	password: string
}

const SignUp: React.FC = () => {
	const { setAuth } = useAuth()
	const [isAuthValid, setIsAuthValid] = useState<boolean>(true)
	const navigate = useNavigate()
	const location = useLocation()
	
	const initialValues: FormValues = {
		email: '',
		password: '',
	}

	const navigatePathname = useMemo(() => {
		const state = location.state as { from: Location }
		if(state && state.from) {
			return state.from
		} else {
			return '/'
		}
	}, [location])
	

	const getUserAuth = (values: FormValues) => {
		const { email, password } = values

		axios.post('/auth', { email, password })
			.then(res => {
				const { accessToken } = res.data
				setAuth({ accessToken })
				navigate(navigatePathname, { replace: true })
			})
			.catch(error => {
				setIsAuthValid(false)
				throw new Error(error)
			})
	}

	return (
		<section className='SignIn'>
			<img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt='Logo LeLabo' />
			<div className="SignIn-title">
				<h1>Bonjour, entrez dans Le Labo</h1>
				<p>Connectez-vous pour continuer.</p>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={signInSchema}
				onSubmit={(values: FormValues, { setSubmitting }) => {
					getUserAuth(values)
					setSubmitting(false)
				}}
			>
				<Form className='SignIn-form'>
					<Field
						type='email'
						name='email'
						placeholder='Adresse e-mail'
						className='input-text'
					/>
					<ErrorMessage name='email' component='p' className='error-msg' />

					<Field
						type='password'
						name='password'
						placeholder='Mot de passe'
						className='input-text'
					/>
					<ErrorMessage name='password' component='p' className='error-msg' />
					{!isAuthValid && <p className='error-msg'>E-mail / Mot de passe incorrect</p>}

					<button type='submit' className='input-submit'>Connexion</button>
				</Form>
			</Formik>
		</section>
	)
}
export default SignUp