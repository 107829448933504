import { useState, useEffect, useContext } from 'react'
import { MdOutlineBarChart, MdPerson, MdCalendarToday, MdEuroSymbol, MdOutlineMouse, MdRemoveRedEye } from 'react-icons/md'
import { DateRangePicker } from 'rsuite'
import { Slide, toast, ToastContainer } from 'react-toastify'
import StatItem from '../StatItem/StatItem'
import BarChart from '../Charts/BarChart'
import LineChart from '../Charts/LineChart'
import Campaigns from '../Campaigns/Campaigns'
import axios from '../../api/axios'
import AuthContext from '../../context/AuthProvider'
import { today, firstDayOfTheMonth, locale, ranges } from '../../helpers/dates'
import { dateToFormattedString, roundNumbers } from '../../helpers'
import { Stats, TimeRange, InfosPerMonth } from '../../interfaces'
import 'rsuite/dist/rsuite.min.css'
import './Dashboard.scss'

const Dashboard: React.FC = () => {
	const { auth } = useContext(AuthContext)
	const [stats, setStats] = useState<Stats>({
		impressions: 0,
		reach: 0,
		clicks: 0,
		budget: 0,
		guides: 0,
		appointements: 0,
	})
	const [timeRange, setTimeRange] = useState<TimeRange>({
		since: dateToFormattedString(firstDayOfTheMonth),
		until: dateToFormattedString(today),
	})
	const [infosPerMonth, setInfosPerMonth] = useState<InfosPerMonth>({
		guides: [],
		appointments: [],
	})
	const options = {
		headers: {
			'Authorization': `Bearer ${auth.accessToken}`
		}
	}

	useEffect(() => {
		getFacebookInfos()
		getGoogleInfos()
		getInfosPerMonth()
	}, [])

	
	const getFacebookInfos = () => {
		axios.post('facebook/timeranged', timeRange, options)
			.then(res => {
				let budget = +res.data[0]?.spend ?? 0

				if(budget !== 0) {
					budget += budget * 0.25
				}
				
				setStats(prev => {
						return {
							...prev,
							impressions: +res.data[0]?.impressions ?? 0,
							reach: +res.data[0]?.reach ?? 0,
							clicks: +res.data[0]?.inline_link_clicks ?? 0,
							budget,
						}
					})
				})
			.catch(error => {
				throw new Error(error)
			})
		}
		
		const getGoogleInfos = () => {
			axios.post('google/timeranged', timeRange, options)
				.then(res => {
					const { data } = res.data
					setStats(prev => {
						return {
							...prev,
							guides: data.guides,
							appointements: data.eligibility,
						}
					})
				})
				.catch(error => {
					throw new Error(error)
				})
		}
		
		const getInfosPerMonth = () => {
			axios.get('google/months', options)
			.then(res => {
				const { data } = res.data
				data.guidesPerMonth[9] = 188
				data.eligPerMonth[9] = 27

				setInfosPerMonth({
					guides: data.guidesPerMonth,
					appointments: data.eligPerMonth
				})
			})
			.catch(error => {
				throw new Error(error)
			})
	}
	
	const handleChange = (value: any) => {
		const since = dateToFormattedString(value[0])
		const until = dateToFormattedString(value[1])
		
		if(!since || !until) {
			setTimeRange({
				since: dateToFormattedString(firstDayOfTheMonth),
				until: dateToFormattedString(today),
			})
		}
		
		setTimeRange({ since, until })
	}
	
	const handleSubmit = async () => {
		if(!timeRange?.since || !timeRange?.until) {
			return toast.error('Durée incorrecte', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				pauseOnFocusLoss: false,
				draggable: true,
			})
		}
		getFacebookInfos()
		getGoogleInfos()
	}
	
	return (
		<section className='Main'>
			<ToastContainer limit={1} transition={Slide} />

			<p>Tableau de bord</p>

			<div className="stats-filter">	
				<DateRangePicker
					placeholder='Période à observer'
					placement='bottomEnd'
					format='dd/MM/yyy'
					character=' au '
					editable={false}
					isoWeek
					locale={locale}
					ranges={ranges}
					disabledDate={DateRangePicker.allowedRange?.(new Date('2021-11-09'), today)}
					defaultValue={[firstDayOfTheMonth, today]}
					renderValue={(value: any) => {
						for (const range of ranges) {
							if (range.value[0] === value[0] && range.value[1] === value[1]) {
								return range.label
							}
						}
					 return (new Date(value[0]).toLocaleDateString()) + ' au ' + (new Date(value[1]).toLocaleDateString())
					}}
					onChange={(value) => handleChange(value)}
					onOk={() => handleSubmit()}
					onExit={() => handleSubmit()}
				/>
			</div>

			<div className='stats-container'>
				<StatItem
					name='Impressions'
					bgColor='primary'
					icon={<MdOutlineBarChart />}
					content={stats.impressions}
					costPer={roundNumbers(stats.budget, stats.impressions, 1000)}
					desc='Coût par apparition de vos publicités'
					moreInfos='Nombre de fois où vos publicités sont apparues sur un écran.'
				/>
				<StatItem
					name='Couverture'
					bgColor='primary'
					icon={<MdRemoveRedEye />}
					content={stats.reach}
					costPer={roundNumbers(stats.budget, stats.reach, 1000)}
					desc='Coût par personne touchée'
					moreInfos="Nombre d'utilisateurs uniques qui ont vu vos publicités."
				/>
				<StatItem
					name='Clics'
					bgColor='primary'
					icon={<MdOutlineMouse />}
					content={stats.clicks}
					costPer={roundNumbers(stats.budget, stats.clicks, 100)}
					desc='Coût par clic sur votre site'
				/>
				<StatItem
					name='Budget publicitaire'
					bgColor='primary'
					icon={<MdEuroSymbol />}
					content={stats.budget}
					currency='€'
				/>
				<StatItem
					name='Nouveaux prospects'
					bgColor='primary'
					icon={<MdPerson />}
					content={stats.guides}
					costPer={roundNumbers(stats.budget, stats.guides, 100)}
					desc='Coût par prospect'
				/>
				<StatItem
					name='Demandes de RDV'
					bgColor='primary'
					icon={<MdCalendarToday />}
					content={stats.appointements}
					costPer={roundNumbers(stats.budget, stats.appointements, 100)}
					desc='Coût par demande'
				/>
			</div>

			<p className='month-results'>Résultats mensuels</p>

			<div className="chart-container">
				<div className='chart-wrapper'>
					<BarChart infosPerMonth={infosPerMonth} />
				</div>

				<div className="chart-wrapper">
					<LineChart infosPerMonth={infosPerMonth} />
				</div>
			</div>

			<Campaigns />
		</section>
	)
}
export default Dashboard