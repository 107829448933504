import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { User } from '../../interfaces'
import InfosForm from '../Forms/InfosForm'
import AddressForm from '../Forms/AddressForm'
import PwdForm from '../Forms/PwdForm'
import './Settings.scss'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
	user: User
}

const Settings: React.FC<Props> = ({ user }) => {
	const [activeTab, setActiveTab] = useState<number>(0)
	
	return (
		<section className='Main'>
			<ToastContainer />
			<nav className='navigation'>
				<p
					className={activeTab === 0 ? 'nav-tab active' : 'nav-tab'}
					onClick={() => setActiveTab(0)}
				>
					Informations personnelles
				</p>
				<p
					className={activeTab === 1 ? 'nav-tab active' : 'nav-tab'}
					onClick={() => setActiveTab(1)}
				>
					Adresse de facturation
				</p>
				<p
					className={activeTab === 2 ? 'nav-tab active' : 'nav-tab'}
					onClick={() => setActiveTab(2)}
				>
					Mot de passe
				</p>
			</nav>

			<InfosForm activeTab={activeTab} user={user} />
			<AddressForm activeTab={activeTab} user={user} />
			<PwdForm activeTab={activeTab} />
		</section>
	)
}
export default Settings