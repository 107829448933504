import { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import axios from '../../../api/axios'
import { BillingAddress, User } from '../../../interfaces'
import { addressSchema } from '../../../schemas'
import AuthContext from '../../../context/AuthProvider'

interface Props {
	activeTab: number
	user: User
}

const AddressForm: React.FC<Props> = ({ activeTab, user }) => {
	const { auth } = useContext(AuthContext)

	const { billing_address } = user
	const initialValues = {
		street: billing_address?.street ?? '',
		city: billing_address?.city ?? '',
		zipcode: billing_address?.zipcode ?? '',
		country: billing_address?.country ?? ''
	}
	const options = {
		headers: {
			'Authorization': `Bearer ${auth.accessToken}`
		}
	}

	const updateAddress = async (values: BillingAddress) => {
		axios.patch('/users/address', values, options)
			.then(res => {
				if(res.status != 201) {
					return toast.error('Erreur lors de la modification de votre adresse.', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					})
				}

				toast.success('Votre adresse a été modifiée avec succès.', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				})
			})
			.catch(error => {
				throw new Error(error)
			})
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={addressSchema}
			enableReinitialize={true}
			onSubmit={(values: BillingAddress, { setSubmitting }) => {
				updateAddress(values)
				setSubmitting(false)
			}}
		>
			<Form className={activeTab === 1 ? 'address-form active' : 'address-form'}>
				<div className='address-form-section'>
					<p>Adresse de facturation</p>
					<div>
						<label htmlFor='street'>Rue :</label>
						<Field 
							type='text'
							name='street'
							placeholder='Rue'
						/>
						<ErrorMessage name='street' component='p' />
					</div>
					<div>
						<label htmlFor='city'>Ville :</label>
						<Field 
							type='text'
							name='city'
							placeholder='Ville'
						/>
						<ErrorMessage name='city' component='p' />
					</div>
					<div>
						<label htmlFor='zipcode'>Code postal :</label>
						<Field 
							type='text'
							name='zipcode'
							placeholder='Code postal'
						/>
						<ErrorMessage name='zipcode' component='p' />
					</div>
					<div>
						<label htmlFor='country'>Pays :</label>
						<Field 
							type='text'
							name='country'
							placeholder='Pays'
						/>
						<ErrorMessage name='country' component='p' />
					</div>
				</div>

				<div className="address-form-section">
					<button type='submit'>Modifier mon adresse</button>
				</div>
			</Form>
		</Formik>
  )
}
export default AddressForm