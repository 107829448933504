import { useEffect, useState } from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { ThreeCircles } from 'react-loader-spinner'

interface Props {
	name: string
	bgColor: string
	icon: React.ReactNode
	content: number
	currency?: string
	costPer?: number
	desc?: string
	moreInfos?: string
}

const StatItem: React.FC<Props> = ({ name, bgColor, icon, content, currency, costPer, desc, moreInfos }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setIsLoading(true)

		setTimeout(() => {
			setIsLoading(false)
		}, 1000)
	}, [content])

	return (
		<div className={`stats-item ${bgColor}`}>
			<div className='stats-wrapper'>
				<p>
					{name}
					{moreInfos && <MdInfoOutline onMouseEnter={() => setIsDialogOpen(true)} onMouseLeave={() => setIsDialogOpen(false)} />}
				</p>
				{isDialogOpen && <div className="dialog-box">{moreInfos}</div>}
				{icon}
			</div>

			{
				isLoading ?
					<ThreeCircles
						height="80"
						width="80"
						color="#FFF"
						visible={true}
						ariaLabel="three-circles-rotating"
					/>
				:
					<>
						<p>{content.toLocaleString()} {currency && currency}</p>
						{costPer && <span>{desc} : {costPer.toLocaleString()} €</span>}
					</>
			}
		</div>
	)
}
export default StatItem