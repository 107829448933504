import { useContext } from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { toast } from 'react-toastify'
import axios from '../../../api/axios'
import { passwordSchema } from '../../../schemas'
import AuthContext from '../../../context/AuthProvider'

interface Props {
	activeTab: number
}

const PwdForm: React.FC<Props> = ({ activeTab }) => {
	const { auth } = useContext(AuthContext)

	const options = {
		headers: {
			'Authorization': `Bearer ${auth.accessToken}`
		}
	}

	const updatePassword = async (values: { password: string; newPassword: string }) => {
		axios.patch('/users/secret', values, options)
			.then(res => {
				if(res.status != 201) {
					return toast.error('Erreur lors de la modification de votre adresse.', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					})
				}

				toast.success('Votre adresse a été modifiée avec succès.', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				})
			})
			.catch(error => {
				throw new Error(error)
			})
	}

	return(
		<Formik
			initialValues={{
				password: '',
				newPassword: '',
			}}
			validationSchema={passwordSchema}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false)
				updatePassword(values)
			}}
		>
			<Form className={activeTab === 2 ? 'infos-form active' : 'infos-form'}>
				<div className='infos-form-section'>
					<p>Mot de passe</p>
					<div>
						<label htmlFor='password'>Mot de passe actuel :</label>
						<Field
							type='password'
							name='password'
							placeholder='Mot de passe actuel'
						/>
						<ErrorMessage name='password' component='p' />
					</div>
					<div>
						<label htmlFor='newPassword'>Nouveau mot de passe :</label>
						<Field
							type='password'
							name='newPassword'
							placeholder='Nouveau mot de passe'
						/>
						<ErrorMessage name='newPassword' component='p' />
					</div>
				</div>
				<div className="infos-form-section">
					<button type='submit'>Modifier mon mot de passe</button>
				</div>
			</Form>
		</Formik>
	)
}

export default PwdForm